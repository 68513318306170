import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import NamesList from "../components/namesList"
import Divedowns from "../components/divedowns"
import Ad from "../components/ad"

import { DetailedAttributeList } from "../components/attribute"

import { Helmet } from "react-helmet"

const Parents = ({ parents }) => (
  <ul style={{
    width: "100%",
    margin: "0px",
    padding: "0px"
  }}>
    {parents.slice(1).map((parent) => (
      <li style={{
        display: "inline-block",
        margin: "10px",
        textDecoration: "underline"
      }} key={parent.url}>
        <Link to={'/' + parent.url}>{parent.title}</Link>
      </li>
    ))}
  </ul>
)


export default function Page({ data, location }) {
  return (
    <Layout location={location}>
      <Helmet>
        <title>{data.pagesJson.title}</title>
        <meta name="description"
          content={
            data.pagesJson.number_of_names + " Vornamen mit Bedeutung, Herkunft und Statistiken."
          } />
        <meta name="keywords"
          content={data.pagesJson.attributes.map(attr => attr.id).join(', ')} />
        <link rel="canonical" href={'/' + data.pagesJson.url} />
      </Helmet>
      <div className="paper">
        <h1 style={{ width: "100%" }}>{data.pagesJson.title}</h1>
        <Parents parents={data.pagesJson.parents} />
        <DetailedAttributeList attributes={data.pagesJson.attributes} />
      </div>
      <NamesList names={data.allNamesJson.nodes} initial_limit={30} />
      <Ad id="adnz_maxiboard_1" />
      <Divedowns divedowns={data.pagesJson.divedowns} />
      <Parents parents={data.pagesJson.parents} />
      <Ad id="adnz_maxiboard_2" />
    </Layout>
  )
}


export const query = graphql`
query($url: String!, $name_ids: [String!]) {
  pagesJson(url: {eq: $url }) {
    title
    url
    attributes {
          imagePath
      associated_page_url
      births {
        births
        years
      }
      category
      id
      latest_births
      popularity
      rank
      title
      trend
    }
    parents {
      title
      url
    }
    divedowns {
      attribute {
          imagePath
        category
        id
        title
        latest_births
        births {
          years
          births
        }
        popularity
        trend
      }
      number_of_names
      subpage_url
    }
  }
  allNamesJson(
    filter: {id: {in: $name_ids}}, 
    limit: 100, 
    sort: {order: DESC, fields: popularity}) {
    nodes {
      attributes {
          imagePath
        associated_page_url
        category
        id
        latest_births
        trend
      }
      births {
        births
        years
      }
      gender
      id
      intro
      latest_births
      meaning
      name
      origin
      popularity
      syllables
      trend
    }
  }
}`
